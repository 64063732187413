import store from '@/store'
import phoneCodes from '@/data/phone_code'

interface Phone {
  country_id: number;
  name: string;
  number: string;
  phone_code: number;
}
interface Times {
  days: number;
  hours: number;
  min: number;
  sec: number;
}

let scrollPosition = 0

const throttle = (fn: any, wait: number) => {
  let time = Date.now()
  return () => {
    if ((time + wait - Date.now()) < 0) {
      fn()
      time = Date.now()
    }
  }
}

const scrollToDynamic = (isOpen: boolean, scrollPosition: number) => {
  const { body } = document
  isOpen ? (body.style.top = `-${scrollPosition}px`) : window.scrollTo(0, scrollPosition)
}

const createDimEl = (isOpen: boolean): void => {
  isOpen && (scrollPosition = window.pageYOffset)
  const dimEl = document.createElement('div')
  const { body } = document
  const actionType = isOpen ? 'appendChild' : 'removeChild'
  const targetEl = isOpen ? dimEl : body.querySelector('.global-dim')
  dimEl.className = 'global-dim'
  body.classList.toggle('modal-open', isOpen)
  targetEl && body[actionType](targetEl)
  scrollToDynamic(isOpen, scrollPosition)
}

const focusInput = (isOpen: boolean, input: null | HTMLInputElement): void => {
  const actionType = isOpen ? 'focus' : 'blur'
  input && input[actionType]()
}

const phoneByCountryId = (id: number): Phone | any => {
  const res = (phoneCodes as Phone[]).find(({ country_id: cid }) => +cid === +id) || {}
  return res
}

const remainingTimes = (endDate: Date | string): Times => {
  const gap = new Date(endDate).getTime() - new Date().getTime()
  const MILLISECOND = 1000
  const MINUTE = MILLISECOND * 60
  const HOUR = MINUTE * 60
  const DAY = HOUR * 24
  const days = Math.floor(gap / DAY)
  const hours = Math.floor((gap % DAY) / HOUR)
  const min = Math.floor((gap % HOUR) / MINUTE)
  const sec = Math.floor((gap % MINUTE) / MILLISECOND)
  const times = { days, hours, min, sec }
  return times
}

const toCapitalize = (string = ''): string => {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
}

const toReadableNumber = (number = 0, customLocale = 'en-US', options = {}): string => {
  const [, locale] = window.location.pathname.split('/')
  const localeWithSub = window.navigator.languages.find(language => language.includes(locale === 'kh' ? 'km-' : `${locale}-`))
  return number.toLocaleString(customLocale || localeWithSub, options)
}

const getDateTime = (date = new Date(), requireTime = true, customOptions?: Intl.DateTimeFormatOptions) => {
  const options: Record<string, Intl.DateTimeFormatOptions> = {
    date: { year: 'numeric', month: 'long', day: 'numeric' },
    time: { hour: 'numeric', minute: 'numeric' }
  }
  const getOptions: Intl.DateTimeFormatOptions = requireTime
    ? { ...options.date, ...options.time }
    : { ...options.date }
  try {
    return Intl.DateTimeFormat(store.state.lang, customOptions || getOptions).format(new Date(date))
  } catch {
    return ''
  }
}

export {
  throttle,
  createDimEl,
  getDateTime,
  focusInput,
  phoneByCountryId,
  remainingTimes,
  toCapitalize,
  toReadableNumber
}
