<template>
  <div>
    <SkeletonTabCouponType />
    <SkeletonCoupons />
  </div>
</template>

<script>
import SkeletonTabCouponType from '@/components/skeleton/items/TabCouponType.vue'
import SkeletonCoupons from '@/components/skeleton/items/Coupons.vue'

export default {
  components: {
    SkeletonTabCouponType,
    SkeletonCoupons
  }
}
</script>
