






































import { computed, defineComponent } from '@vue/composition-api'
import store from '@/store'
import { inviteCredits, phoneByCountryId, toReadableNumber, sendToApp } from '@/helpers'
import { useClipboard, useI18n } from 'vue-composable'
import { IS_LOGGING } from '@/data/constants'

export default defineComponent({
  setup (_, { emit }) {
    const { $t } = useI18n()

    const {
      state: {
        auth
      },
      getters,
      commit
    } = store

    const closeModal = () => {
      emit('close')
    }

    const { writeText: copyText } = useClipboard()

    const copyInviteCode = (text = '') => {
      sendToApp('copy_myInviteCode', {}, IS_LOGGING)
      copyText(text)
      commit('alert/showAlert', 'share')
    }

    const phoneNumber = computed(() => {
      if (!auth.person.phone) return ''
      const phoneObj = phoneByCountryId(auth.person.phone.code)
      return `${phoneObj.phone_code}${auth.person.phone.number}`
    })

    return {
      $t,
      auth,
      closeModal,
      copyInviteCode,
      inviteCredits,
      sourceCountryCode: computed(() => getters['country/$currentSourceCountryCode']),
      sourceCurrencyIso: computed(() => getters['country/$currentSourceCurrencyIso']),
      phoneNumber,
      toReadableNumber
    }
  }
})
