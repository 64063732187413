



























































import { defineComponent, PropType, onMounted, reactive, toRefs, ref } from '@vue/composition-api'
import couponItem from '@/mixins/couponItem.ts'
import { sendToApp } from '@/helpers'
import { useI18n } from 'vue-composable'

import type { ICoupon } from '@/types'

interface State {
  showStickyTitle: boolean
}

export default defineComponent({
  components: {
  },
  props: {
    selectedCoupon: {
      type: Object as PropType<{ card: ICoupon }>,
      default: () => ({})
    }
  },
  setup (props) {
    const { $t } = useI18n()

    const {
      getCard,
      isMyCoupon,
      labels,
      remainingCoupons,
      remainingTimer,
      couponBenefitLabel
    } = couponItem(props.selectedCoupon)

    const handleScroll = () => {
      const panelContainerTop = slidingUpPanel.value?.getBoundingClientRect().top as number
      const panelHeader = slidingUpPanelHeader.value?.getBoundingClientRect().top as number
      const gap = panelContainerTop - panelHeader
      const enableSticky = gap > 25
      state.showStickyTitle = enableSticky
    }

    const slidingUpPanel = ref<HTMLElement | null>(null)
    const slidingUpPanelHeader = ref<HTMLElement | null>(null)
    const panelContainer = ref<HTMLElement | null>(null)

    const state = reactive({
      showStickyTitle: false,
      $t,
      eventDetailParams: (url: string) => {
        const title = $t('Global.label.event.blank').value
        const isSelfEvent = !/^(((http(s?)):\/\/))/.test(url)
        if (!isSelfEvent) return { baseUri: url, title }
        const path = `/${url}`
        return { baseUri: process.env.VUE_APP_SENTBE_DOMAIN, path, title }
      }
    }) as State

    const hideSlidingUpPanel = () => {
      const slidingUpPanelContainer = panelContainer.value as HTMLElement
      slidingUpPanelContainer.removeEventListener('scroll', handleScroll)
    }

    onMounted(() => {
      const slidingUpPanelContainer = panelContainer.value as HTMLElement
      slidingUpPanelContainer.addEventListener('scroll', handleScroll)
    })

    return {
      ...toRefs(state),
      hideSlidingUpPanel,
      slidingUpPanel,
      slidingUpPanelHeader,
      panelContainer,
      getCard,
      isMyCoupon,
      labels,
      remainingCoupons,
      remainingTimer,
      couponBenefitLabel,
      sendToApp
    }
  }
})
