







import SkeletonMainVisual from '@/components/skeleton/items/MainVisual.vue'
import SkeletonAboutBenefit from '@/components/skeleton/items/AboutBenefit.vue'

export default {
  components: {
    SkeletonMainVisual,
    SkeletonAboutBenefit
  }
}
