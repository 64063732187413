import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { objectDeepSearch } from '@/helpers'
import locales from '@/data/locales'
import alert from './modules/alert'
import auth from './modules/auth'
import country from './modules/country'
import coupon from './modules/coupon'
import modal from './modules/modal'
import util from './modules/util'

import type { ILocale } from '@/types'

Vue.use(Vuex)
export interface RootState {
  [key: string]: any;
}
const { language: BrowserLanguage } = navigator
const isValidLanguage = (lang: string) => {
  const validLocales = (locales as ILocale[]).map(({ locale }) => locale.slice(0, 2))
  return validLocales.includes(lang)
}

const store: StoreOptions<RootState> = {
  state: {
    lang: isValidLanguage(BrowserLanguage) ? BrowserLanguage.slice(0, 2) : 'en',
    hasBaseData: false,
    isHttpRequesting: false
  },
  getters: {
  },
  mutations: {
    $SET_STATE (state, { path, value }) {
      const { pointer, key } = objectDeepSearch({ model: state, path })
      pointer[key] = value
    },
    initBaseTabValue (state) {
      const isNeedToInitializedValues = [
        'coupon.currentTabIndex.status',
        'coupon.currentTabIndex.type'
      ]
      const DEFAULT_VALUE = 0
      for (let i = 0; i < isNeedToInitializedValues.length; i++) {
        const path = isNeedToInitializedValues[i]
        const { pointer, key } = objectDeepSearch({ model: state, path })
        pointer[key] && (pointer[key] = DEFAULT_VALUE)
      }
    }
  },
  actions: {
  },
  modules: {
    alert,
    auth,
    country,
    coupon,
    modal,
    util
  }
}

export default new Vuex.Store(store)
