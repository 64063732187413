import router from '@/router'

interface Params {
  [key: string]: any
}

interface AppEventParams {
  routeName: string
  params: Params
  payload: {
    action: string
    params?: Params | undefined
  }
  actionName: 'TrackingLogBridge' | 'OverseasRemittanceBridge'
}

const goToInApp = (routeName: string): void => {
  (window as any).OverseasRemittanceBridge &&
    (window as any).OverseasRemittanceBridge[routeName]();
  (window as any).webkit &&
    (window as any).webkit.messageHandlers.OverseasRemittanceBridge.postMessage({ action: routeName })
}

const isInApp = (window as any).OverseasRemittanceBridge ||
  (window as any).webkit?.messageHandlers.OverseasRemittanceBridge

const changeRoute = ({ baseUri, path, fullPath }: Params) => {
  const { params, query } = router.app.$route
  if (baseUri === process.env.VUE_APP_SENTBE_DOMAIN) {
    location.href = `${process.env.VUE_APP_SENTBE_DOMAIN}${fullPath}`
    return
  }
  router.push({ path: `/${params.lang}${path}`, query })
}

const appEvent = ({ routeName, params, payload, actionName }: AppEventParams) => {
  (window as any)[actionName] &&
    (window as any)[actionName][routeName] &&
      (window as any)[actionName][routeName](...Object.values(params));
  (window as any).webkit &&
    (window as any).webkit.messageHandlers[actionName].postMessage(payload)
}

const sendToApp = (routeName: string, params: Params = {}, isLogging = false): void => {
  const queryByValidToken = Object.assign({}, router.app.$route.query, { jwt: sessionStorage.jwt, refresh_jwt: sessionStorage.refreshJwt })
  const toQuerystring = Object.entries(queryByValidToken).map(e => e.join('=')).join('&')
  const queryConnector = params.path && params.path.includes('?') ? '&' : '?'
  params.path && (params.fullPath = `/${router.app.$route.params.lang}${params.path}${queryConnector}${toQuerystring}`)
  const payload = {
    action: routeName,
    ...(Object.keys(params).length && { params })
  }
  console.log(`Logging Status: ${isLogging}`)
  console.log(`Method Name: ${routeName}`)
  console.log(`Params: ${JSON.stringify(params)}`)

  const actionName = isLogging ? 'TrackingLogBridge' : 'OverseasRemittanceBridge'
  if (isInApp) return appEvent({ routeName, params, payload, actionName })
  params.path && changeRoute(params)
}

export {
  changeRoute,
  goToInApp,
  isInApp,
  sendToApp
}
