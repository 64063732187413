import Vue from 'vue'
import axios from 'axios'
import { Module } from 'vuex'
import { RootState } from '@/store/index'
import { sendToApp } from '@/helpers'
import { SettlementType } from '@/types/enum'

import type { ICoupon, IMyCoupon } from '@/types'

interface Coupon {
  [key: string]: any;
  myCoupons: IMyCoupon[];
  couponBooks: ICoupon[];
}

const vm: any = new Vue()

const verificationCallback = (message: string) => {
  const callback = {
    message: vm.$i18n(message).value,
    button: vm.$i18n('Global.button.Verify.None').value,
    action: {
      name: sendToApp,
      type: 'verifications'
    }
  }
  return callback
}

const module: Module<Coupon, RootState> = {
  namespaced: true,
  state: {
    currentTabIndex: {
      status: 0,
      type: 0
    },
    myCoupons: [],
    couponBooks: [],
    recentlyReceivedCoupon: {},
    recentlyUsedCoupon: {}
  },
  getters: {
    myCouponByCountry: (state, getters, rootState, rootGetters) => {
      const filteredCoupons = state.myCoupons.filter(card => {
        const ALL_COUNTRY_ID = 0
        const isFilterConditions = {
          source: [ALL_COUNTRY_ID, rootGetters['country/$currentSourceCountryObject'].id].some(cid => {
            return card.coupon.source_countries.includes(cid)
          }),
          destination: [ALL_COUNTRY_ID, rootGetters['country/$currentDestinationCountryObject'].id].some(cid => {
            return card.coupon.destination_countries.includes(cid)
          })
        } as const
        return Object.keys(isFilterConditions).every(condition => {
          return isFilterConditions[condition as keyof typeof isFilterConditions]
        })
      })
      return filteredCoupons
    },
    couponsByCountry: (state, getters, rootState, rootGetters) => {
      const filteredCoupons = state.couponBooks.filter(card => {
        const ALL_COUNTRY_ID = 0
        const isNoTimeLimit = (date: Date | string) => {
          return !new Date(date).getTime() || new Date(date).getTime() < 0
        }
        const isFilterConditions = {
          source: [ALL_COUNTRY_ID, rootGetters['country/$currentSourceCountryObject'].id].some(cid => {
            return card.source_countries.includes(cid)
          }),
          destination: [ALL_COUNTRY_ID, rootGetters['country/$currentDestinationCountryObject'].id].some(cid => {
            return card.destination_countries.includes(cid)
          }),
          visible: (isNoTimeLimit(card.visible_start) || (new Date().getTime() >= new Date(card.visible_start).getTime())) &&
            (isNoTimeLimit(card.visible_end) || new Date().getTime() <= new Date(card.visible_end).getTime()),
          isRemaining: card.issuance_restrictions.issue_count === 0 || card.issuance_restrictions.issue_count - card.issued_count > 0,
          notDuplicated: getters.myCouponByCountry.every((mCoupon: IMyCoupon) => mCoupon.coupon_id !== card.id)
        } as const
        return Object.keys(isFilterConditions).every(condition => {
          return isFilterConditions[condition as keyof typeof isFilterConditions]
        })
      })
      return filteredCoupons
    },
    labels: () => {
      const labels = {
        couponNotFound: {
          status: [
            vm.$i18n('Benefit.guide.NoCouponsUse.none').value,
            `${vm.$i18n('Benefit.guide.NoMoreCoupons.none').value}`,
            `${vm.$i18n('Benefit.guide.NoMoreCoupons.none').value}`
          ],
          type: [
            vm.$i18n('Benefit.guide.ReceiveCoupons.FeeDicount').value,
            vm.$i18n('Benefit.guide.ReceiveCoupons.Cash').value,
            vm.$i18n('Benefit.guide.ReceiveCoupons.Luckybox').value
          ]
        }
      }
      return labels
    },
    settlementAmount: (state) => {
      return state.recentlyReceivedCoupon.settlement_amount?.basic ||
        state.recentlyReceivedCoupon.settlement_amount?.silver ||
        state.recentlyReceivedCoupon.settlement_amount?.gold
    },
    settlementAmountForUsed: (state) => {
      return state.recentlyUsedCoupon.settlement_amount?.basic ||
        state.recentlyUsedCoupon.settlement_amount?.silver ||
        state.recentlyUsedCoupon.settlement_amount?.gold
    }
  },
  mutations: {},
  actions: {
    showAlertByUseCoupon ({ commit }, { card, type }: { card: ICoupon, type: string }) {
      commit('$SET_STATE', { path: 'coupon.recentlyUsedCoupon', value: card }, { root: true })
      commit('alert/showAlert', type, { root: true })
    },
    closeCouponNumberPopup ({ commit, rootState }) {
      const couponNumberPopups = ['registerCouponNumber', 'inputCouponNumber']
      const isOpenedPopup = couponNumberPopups.some(popupName => rootState.modal.visibles.includes(popupName))
      isOpenedPopup && commit('modal/$CLOSE_MODAL', null, { root: true })
    },
    refreshMyCouponLength ({ commit, rootState }) {
      const value = Object.assign({}, rootState.auth.person, { active_coupon: rootState.auth.person.active_coupon += 1 })
      commit('$SET_STATE', { path: 'auth.person', value }, { root: true })
    },
    async refreshCouponBook ({ commit, rootGetters }) {
      const { data: { data: couponCards } } = await axios.get('/api/person/coupon/book', { params: { currency: rootGetters['country/$currentSourceCurrencyId'] } })
      const cards = (couponCards as ICoupon[])
        .sort((a, b) => a.order - b.order)
        .map((card) => Object.assign({}, card, { flipped: false }))
      commit('$SET_STATE', { path: 'coupon.couponBooks', value: cards }, { root: true })
    },
    actionsBySuccessGetting ({ commit, dispatch }, settlementType) {
      dispatch('closeCouponNumberPopup')
      dispatch('refreshMyCouponLength')
      dispatch('refreshCouponBook')
      dispatch('refreshMyCoupons')
      if ([SettlementType.DISCOUNT_RATE, SettlementType.DISCOUNT_FIXED].includes(settlementType)) return commit('alert/showAlert', 'receivedDiscount', { root: true })
      if ([SettlementType.CASHBACK].includes(settlementType)) return commit('modal/$OPEN_MODAL', 'receive-cashback-modal', { root: true })
      if ([SettlementType.LUCKBOX_BLUE, SettlementType.LUCKBOX_GOLD].includes(settlementType)) return commit('modal/$OPEN_MODAL', 'receive-luckybox-modal', { root: true })
    },
    async GET_COUPON ({ commit, dispatch, rootGetters }, { id, code }: ICoupon) {
      const tryReceiveCoupon = id
      const message = tryReceiveCoupon
        ? vm.$i18n('Benefit.guide.BeforeReceivingCoupons.CompleteVerification').value
        : vm.$i18n('Benefit.guide.RegisterCoupon.CompleteVerification').value
      if (!rootGetters['auth/isVerifyCompleteStatus']) {
        await dispatch('closeCouponNumberPopup')
        commit('modal/$OPEN_MODAL', { modalName: 'warning', callback: verificationCallback(message) }, { root: true })
        return
      }
      const payload = {
        pid: rootGetters['auth/$getPid'],
        ...(id && { coupon_id: +id }),
        ...(code && { code: code.toUpperCase() })
      }
      const { code: errorCode, data: coupon }: { code: number, data: ICoupon } = await axios.post('/api/person/coupon', payload)
      const amplitudeProperties = {
        coupon_id: coupon.id,
        success: errorCode === 200 ? 'yes' : 'no',
        error_code: errorCode === 200 ? 'null' : errorCode
      }
      const isLogging = true
      id && sendToApp('get_coupons', amplitudeProperties, isLogging)
      !id && code && sendToApp('complete_registeringCoupon', { coupon_id: coupon.id }, isLogging)
      const receivedCoupon = coupon?.settlement_type
      if (receivedCoupon) {
        commit('$SET_STATE', { path: 'coupon.recentlyReceivedCoupon', value: coupon }, { root: true })
        dispatch('actionsBySuccessGetting', receivedCoupon)
      }
    },
    async refreshMyCoupons ({ commit, rootGetters }) {
      const { data: { data: couponCards } } = await axios.get('/api/person/coupon/box', { params: { pid: rootGetters['auth/$getPid'] } })
      const cards = (couponCards as IMyCoupon[])
        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
        .map((card) => Object.assign({}, card, { coupon: Object.assign({}, card.coupon, { flipped: false }) }))
      commit('$SET_STATE', { path: 'coupon.myCoupons', value: cards }, { root: true })
    },
    actionsBySuccessUsing ({ state, dispatch }, card: ICoupon) {
      const couponTypes = {
        1: 'usedCash',
        2: 'usedLuckybox'
      }
      dispatch('refreshMyCoupons')
      dispatch('showAlertByUseCoupon', { card, type: couponTypes[state.currentTabIndex.type as keyof typeof couponTypes] })
    },
    async USE_COUPON ({ rootGetters, commit, dispatch }, { card, id }: { card: ICoupon, id: string }) {
      if (!rootGetters['auth/isVerifyCompleteStatus']) return commit('modal/$OPEN_MODAL', { modalName: 'warning', callback: verificationCallback('Benefit.guide.CompleteVerification.none') }, { root: true })
      const payload = {
        pid: rootGetters['auth/$getPid'],
        coupon_box_id: +id
      }
      await axios.put('/api/person/coupon', payload)
      dispatch('actionsBySuccessUsing', card)
    }
  }
}

export default module
