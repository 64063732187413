import app from '@/main'
import { Module } from 'vuex'
import { RootState } from '@/store/index'
import { sendToApp } from '@/helpers'

interface Modal {
  [key: string]: any;
}

interface Resource {
  modalName: string;
  callback: any;
  showBackdropInApp: boolean;
}

const module: Module<Modal, RootState> = {
  namespaced: true,
  state: {
    visibles: [],
    callbacks: []
  },
  getters: {},
  mutations: {
    $OPEN_MODAL (state, resource) {
      const isObject = resource && typeof resource === 'object'
      const { modalName, callback, showBackdropInApp = true }: Resource = isObject ? resource : { modalName: resource }
      state.visibles = [...state.visibles, modalName]
      callback && (state.callbacks = [...state.callbacks, callback])
      showBackdropInApp && sendToApp('openedModal', { value: true })
    },
    $CLOSE_MODAL (state) {
      const { visibles, callbacks } = state
      const modalName = visibles.pop();
      (app as any).$bvModal.hide(modalName)
      callbacks.length && callbacks.pop()
      sendToApp('openedModal', { value: false })
    }
  },
  actions: {}
}

export default module
