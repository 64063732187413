import Vue from 'vue'
import {
  BAlert,
  BIconChevronRight,
  BIconDownload,
  BIconGiftFill,
  BIconPeopleFill,
  ButtonPlugin,
  DropdownPlugin,
  BIconChevronDown,
  BIconChevronUp,
  BIconArrowLeft,
  BIconPlusCircle,
  BIconXCircleFill,
  BOverlay,
  FormPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  BIconQuestion,
  TooltipPlugin,
  BIconArrowRight,
  ModalPlugin,
  BIconX,
  CardPlugin,
  CollapsePlugin,
  SkeletonPlugin,
  ToastPlugin,
  BCarousel,
  BCarouselSlide
} from 'bootstrap-vue'

Vue.component('BAlert', BAlert)
Vue.component('BIconChevronRight', BIconChevronRight)
Vue.component('BIconDownload', BIconDownload)
Vue.component('BIconGiftFill', BIconGiftFill)
Vue.component('BIconPeopleFill', BIconPeopleFill)
Vue.component('BIconChevronDown', BIconChevronDown)
Vue.component('BIconChevronUp', BIconChevronUp)
Vue.component('BIconArrowLeft', BIconArrowLeft)
Vue.component('BIconPlusCircle', BIconPlusCircle)
Vue.component('BIconXCircleFill', BIconXCircleFill)
Vue.component('BOverlay', BOverlay)
Vue.component('BIconQuestion', BIconQuestion)
Vue.component('BIconArrowRight', BIconArrowRight)
Vue.component('BIconX', BIconX)
Vue.component('b-carousel', BCarousel)
Vue.component('b-carousel-slide', BCarouselSlide)

Vue.use(ButtonPlugin)
Vue.use(DropdownPlugin)
Vue.use(FormPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(TooltipPlugin)
Vue.use(ModalPlugin)
Vue.use(CollapsePlugin)
Vue.use(CardPlugin)
Vue.use(SkeletonPlugin)
Vue.use(ToastPlugin)
