

























import { computed, defineComponent, onUnmounted } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
import { useI18n, setI18n } from 'vue-composable'
import { throttle } from '@/helpers'
import Alerts from '@/components/Alerts.vue'
import Modals from '@/components/Modals.vue'
import SkeletonLoginGuide from '@/components/skeleton/LoginGuide.vue'
import SkeletonBenefits from '@/components/skeleton/Benefits.vue'
import SkeletonCouponBook from '@/components/skeleton/CouponBook.vue'
import SkeletonMyCoupon from '@/components/skeleton/MyCoupon.vue'
import SkeletonMyGrade from '@/components/skeleton/MyGrade.vue'
import SkeletonInviteFriend from '@/components/skeleton/InviteFriend.vue'

const {
  state,
  commit
} = store

const [locale] = location.pathname.slice(1).split('/')

const injectLocalization = () => {
  const { $t } = useI18n()
  const vm: any = new Vue()
  !vm.$i18n && (Vue.prototype.$i18n = $t)
}

const endPoint = location.pathname.split('/')[location.pathname.split('/').length - 1]

const closeMessage = () => {
  commit('alert/closeMessage')
}

export default defineComponent({
  metaInfo: {
    title: 'Sentbe',
    htmlAttrs: {
      lang: locale || navigator.language.slice(0, 2)
    }
  },
  components: {
    Alerts,
    Modals,
    'login-guide': SkeletonLoginGuide,
    benefits: SkeletonBenefits,
    'coupon-book': SkeletonCouponBook,
    'my-coupon': SkeletonMyCoupon,
    'my-grade': SkeletonMyGrade,
    'invite-friend': SkeletonInviteFriend
  },
  setup () {
    const FALLBACK_LOCALE = 'en'

    const toValidLocale = (locale: 'bd' | 'ru') => {
      const languages = {
        bd: 'bn_BD',
        ru: 'ru_RU'
      }
      const localeWithSubLanguage = languages[locale]
      return localeWithSubLanguage || locale
    }

    setI18n({
      locale: state.lang,
      fallback: FALLBACK_LOCALE,
      messages: {
        en: require('@/locale/en.json'),
        ...(state.lang !== FALLBACK_LOCALE && { [state.lang]: require(`@/locale/${toValidLocale(state.lang)}.json`) })
      }
    })

    injectLocalization()

    window.addEventListener('scroll', throttle(closeMessage, 500))

    onUnmounted(() => {
      window.removeEventListener('scroll', closeMessage)
    })

    return {
      endPoint,
      hasBaseData: computed(() => state.hasBaseData),
      isHttpRequesting: computed(() => state.isHttpRequesting)
    }
  }
})
