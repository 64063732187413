import { SettlementType } from '@/types/enum'
import sourceCountries from '@/data/source_country.config'

const discountRate = {
  gold: {
    CN: 25,
    default: 100
  },
  silver: {
    CN: 8,
    default: 25
  }
}

const filteredCardTypes = (selectedCouponType: number): number[] => {
  const map = {
    0: [SettlementType.DISCOUNT_RATE, SettlementType.DISCOUNT_FIXED],
    1: [SettlementType.CASHBACK],
    2: [SettlementType.LUCKBOX_BLUE, SettlementType.LUCKBOX_GOLD],
    default: [
      SettlementType.DISCOUNT_RATE,
      SettlementType.DISCOUNT_FIXED,
      SettlementType.LUCKBOX_BLUE,
      SettlementType.LUCKBOX_GOLD,
      SettlementType.CASHBACK
    ]
  }
  return map[selectedCouponType as keyof typeof map] || map.default
}

const inviteCredits = (countryIso = ''): number => {
  if (!countryIso) return 0
  return sourceCountries[countryIso.toUpperCase()].welcome_credit
}

const vipAmountByCountry = (grade = 'basic', iso = 'KR'): number => {
  return sourceCountries[iso].vip[grade] ?? 0
}

export {
  discountRate,
  filteredCardTypes,
  inviteCredits,
  vipAmountByCountry
}
