


















import { computed, defineComponent, reactive, toRefs, watch } from '@vue/composition-api'
import store from '@/store'
import ToastLayout from '@/components/modals/layout/Toast.vue'
import CouponDetails from '@/components/CouponDetails.vue'
import Warning from '@/components/modals/Warning.vue'
import InviteCodeModal from '@/components/modals/InviteCode.vue'
import ReceiveCashBackCoupon from '@/components/modals/ReceiveCashBackCoupon.vue'
import ReceiveLuckyBox from '@/components/modals/ReceiveLuckyBox.vue'
import app from '@/main'

interface State {
  [key: string]: any;
}

export default defineComponent({
  components: {
    ToastLayout,
    CouponDetails,
    Warning,
    InviteCodeModal,
    ReceiveCashBackCoupon,
    ReceiveLuckyBox
  },
  setup () {
    const {
      state: {
        modal
      },
      commit
    } = store

    const state = reactive({
      shownCouponDetails: computed(() => modal.visibles.includes('couponDetails')),
      selectedCoupon: computed(() => {
        const [coupon] = modal.callbacks
        return coupon
      })
    }) as State

    const closeModal = () => {
      commit('modal/$CLOSE_MODAL')
    }

    const openModal = (modalName = '') => {
      (app as any).$bvModal.show(modalName)
    }

    watch(
      () => modal.visibles,
      (visibles) => {
        const modalName = [...visibles].pop()
        openModal(modalName)
      }
    )

    return {
      ...toRefs(state),
      closeModal
    }
  }
})
