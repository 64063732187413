import { Module } from 'vuex'
import { RootState } from '@/store/index'

interface Alert {
  [key: string]: any;
}

const module: Module<Alert, RootState> = {
  namespaced: true,
  state: {
    shownSeconds: 3,
    shownMessageTypes: {
      share: 0,
      receivedDiscount: 0,
      usedCash: 0,
      usedLuckybox: 0,
      error: 0
    },
    errorMessage: '',
    shownHistory: []
  },
  getters: {},
  mutations: {
    showAlert (state, pointer) {
      const { name, timer } = pointer && typeof pointer === 'object'
        ? pointer
        : { name: pointer, timer: state.shownSeconds }
      state.shownMessageTypes[name] = timer
      state.shownHistory = [...state.shownHistory, name]
    },
    closeMessage (state) {
      for (const timer in state.shownMessageTypes) {
        state.shownMessageTypes[timer] && (state.shownMessageTypes[timer] = 0)
        state.errorMessage && (state.errorMessage = '')
      }
    }
  },
  actions: {}
}

export default module
