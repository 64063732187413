




import SkeletonGradeLevel from '@/components/skeleton/items/GradeLevel.vue'

export default {
  components: {
    SkeletonGradeLevel
  }
}
