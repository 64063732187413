import router from '@/router'
import axios from 'axios'
import { Module } from 'vuex'
import { RootState } from '@/store/index'
import locales from '@/data/locales'
import sourceCountries from '@/data/source_country.config'

import type { ICurrency, ICountry, ILocale } from '@/types'

interface Country {
  [key: string]: any;
  countries: ICountry[];
}

const module: Module<Country, RootState> = {
  namespaced: true,
  state: {
    countries: [],
    locales
  },
  getters: {
    $sourceCountries: (state) => {
      const countries = state.countries.reduce((object: ICountry[], country) => {
        Object.keys(sourceCountries).includes(country.iso) &&
          object.push({ ...country, ...sourceCountries[country.iso] })
        return object
      }, [])
      return countries
    },
    $currentSourceCountryObject: (state, getters, rootState) => {
      const sourceCountries: ICountry[] = getters.$sourceCountries
      return sourceCountries.find(({ id }) => id === rootState.auth.person.often_source_country) ||
        sourceCountries.find(({ iso }) => iso === router.currentRoute.query.source_country) ||
        {}
    },
    $currentSourceCountryCode: (_, getters) => {
      return (getters.$currentSourceCountryObject.iso || router.currentRoute.query.source_country) || 'KR'
    },
    $currentSourceCurrencyId: (_, getters) => {
      const [currencyId] = getters.$currentSourceCountryObject.currency_ids || [0]
      return currencyId
    },
    $currentSourceCurrencyIso: (_, getters) => {
      return getters.toCurrencyIso(getters.$currentSourceCurrencyId)
    },
    $currentDestinationCountryObject: (state, _, rootState) => {
      return state.countries.find(({ id }) => id === rootState.auth.person.often_send_country) ||
        state.countries.find(({ iso }) => iso === router.currentRoute.query.destination_country) ||
        {}
    },
    $currentDestinationCountryCode: (_, getters) => {
      return (getters.$currentDestinationCountryObject.iso || router.currentRoute.query.destination_country) || 'US'
    },
    currencies: state => {
      const resourceCurrencies = state.countries.reduce((accu: ICurrency[], { currencies }) => {
        return [...accu, ...currencies]
      }, [])
      const uniqueCurrencies = resourceCurrencies.filter(function ({ id }) {
        return !this.has(id) && this.add(id)
      }, new Set())
      return uniqueCurrencies
    },
    isDomesticSource: (state, getters) => {
      return getters.$currentSourceCountryCode === 'KR'
    },
    toCountryIso: state => (countryId = 0) => {
      const { iso } = state.countries.find(({ id }) => id === +countryId) || {}
      return iso || ''
    },
    toCurrencyIso: (_, getters) => (currencyId = 0) => {
      const WALLET_VALUE = 1000
      const { label = '' } = getters.currencies.find(({ id }: { id: number }) => {
        return id === +currencyId || (id === (+currencyId - WALLET_VALUE))
      }) || {}
      return label.toUpperCase()
    },
    localeId: (state, _, RootState) => {
      const { id } = (state.locales as ILocale[]).find(({ locale }) => locale.slice(0, 2) === RootState.lang) || {}
      return id
    }
  },
  mutations: {},
  actions: {
    INIT_COUNTRIES: async ({ state, commit }) => {
      if (state.countries.length) return
      const { data = {} } = await axios.post('/api/init')
      const { countries = [] } = data
      commit('$SET_STATE', { path: 'country.countries', value: countries }, { root: true })
    }
  }
}

export default module
