













































































































import { computed, defineComponent, reactive, toRefs, watch } from '@vue/composition-api'
import store from '@/store'
import { inviteCredits, sendToApp, toReadableNumber } from '@/helpers'
import { useI18n } from 'vue-composable'
import { IS_LOGGING } from '@/data/constants'

interface State {
  [key: string]: any;
  zIndex: (name: string) => number
}

const BASE_Z_INDEX = 1058

const {
  state: {
    alert,
    auth,
    coupon
  },
  getters,
  commit
} = store

const initErrorMessage = (shownCount = 0) => {
  const hasErrorMessage = !shownCount && alert.errorMessage
  hasErrorMessage && commit('$SET_STATE', { path: 'alert.errorMessage', value: '' })
}

export default defineComponent({
  setup () {
    const { $t } = useI18n()

    const state = reactive({
      errorMessage: computed(() => alert.errorMessage),
      shownMessageTypes: computed(() => alert.shownMessageTypes),
      getLuckyBoxlength: computed(() => toReadableNumber(getters['coupon/settlementAmountForUsed'])),
      zIndex: (name) => alert.shownHistory.lastIndexOf(name) + BASE_Z_INDEX
    }) as State

    const countDownForHideCoupon = (shownCount: number) => {
      for (const type in state.shownMessageTypes) {
        state.shownMessageTypes[type] && commit('$SET_STATE', { path: `alert.shownMessageTypes.${type}`, value: shownCount })
        initErrorMessage(shownCount)
      }
    }

    const labels = computed(() => {
      return {
        cash: coupon.recentlyUsedCoupon.settlement_amount &&
          $t('Benefit.guide.GotCash.none', { amount: toReadableNumber(coupon.recentlyUsedCoupon.settlement_amount[getters['auth/gradeLabel']]), currency: getters['country/toCurrencyIso'](coupon.recentlyUsedCoupon.currency) }).value,
        error: state.errorMessage
      }
    })

    const messageParams = computed(() => {
      const params = {
        invite_code: auth.person.invite_code,
        amount: toReadableNumber(inviteCredits(getters['country/$currentSourceCountryCode'])),
        currency: getters['country/$currentSourceCurrencyIso'],
        app_store_url: `${process.env.VUE_APP_SENTBE_DOMAIN}/onelink?iframe=Y&invite_code=${auth.person.invite_code}`
      }
      return params
    })

    const params = computed(() => {
      return {
        type: 'inviteCode',
        message: $t('Benefit.inviting.friends.deeplink', messageParams).value,
        link: `${process.env.VUE_APP_SENTBE_DOMAIN}/onelink?iframe=Y&invite_code=${auth.person.invite_code}`,
        button: ''
      }
    })

    const clickShare = () => {
      sendToApp('select_shareMyInviteCode', {}, IS_LOGGING)
      sendToApp('shareLink', params.value)
    }

    watch(
      () => state.errorMessage,
      (message) => {
        message && commit('alert/showAlert', 'error')
      }
    )

    return {
      $t,
      sentbeDomain: process.env.VUE_APP_SENTBE_DOMAIN,
      ...toRefs(state),
      countDownForHideCoupon,
      labels,
      messageParams,
      params,
      location,
      sendToApp,
      clickShare
    }
  }
})
