









































import { defineComponent } from '@vue/composition-api'
import { sendToApp, toReadableNumber } from '@/helpers'
import { useI18n } from 'vue-composable'
import { IS_LOGGING } from '@/data/constants'

export default defineComponent({
  setup (_, { emit }) {
    const { $t } = useI18n()

    const closeModal = () => {
      emit('close')
    }

    const goMyCoupon = () => {
      closeModal()
      sendToApp('select_gotoMyCouponBox', {}, IS_LOGGING)
      sendToApp('myCoupon', { baseUri: location.origin, path: '/my-coupon', title: $t('Benefit.button.CouponBox.none').value })
    }

    return {
      $t,
      closeModal,
      goMyCoupon,
      sendToApp,
      toReadableNumber
    }
  }
})
