


























import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api'
import { useI18n } from 'vue-composable'
import { sendToApp } from '@/helpers'
import store from '@/store'

interface State {
  [key: string]: any;
}

const {
  state: {
    modal
  },
  commit
} = store

export default defineComponent({
  setup (_, { emit }) {
    const { $t } = useI18n()

    const state = reactive({
      $t,
      resources: computed(() => {
        const [callback = { action: {} }] = modal.callbacks
        return callback
      })
    }) as State

    const closeModal = () => {
      emit('close')
    }

    const goVerifications = () => {
      state.resources.action.name(state.resources.action.type)
      commit('modal/$CLOSE_MODAL')
    }

    return {
      ...toRefs(state),
      closeModal,
      goVerifications,
      sendToApp
    }
  }
})
