const objectDeepSearch = ({ model = {}, path = '' }: { model: any, path: string }): { pointer: any, key: string } => {
  const list: any[] = path.split('.')
  const key: string = list.pop()
  const pointer = list.reduce((object, prop) => {
    if (object[prop] === undefined) object[prop] = {}
    return object[prop]
  }, model)
  const result = { pointer, key }
  return result
}

export default objectDeepSearch
