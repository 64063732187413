import Vue from 'vue'
import axios from 'axios'
import { Module } from 'vuex'
import { RootState } from '@/store/index'
import { sendToApp, toCapitalize, vipAmountByCountry } from '@/helpers'

interface Auth {
  [key: string]: any;
}

const vm: any = new Vue()

const module: Module<Auth, RootState> = {
  namespaced: true,
  state: {
    grades: {
      0: 'basic',
      1: 'silver',
      2: 'gold'
    } as const,
    person: {},
    invites: {},
    verifiedSourceCountries: []
  },
  getters: {
    $getPid: (state) => +state.person.id,
    expectedGradeLabel: (state) => {
      return state.grades[state.person.estimated_grade]
    },
    gradeLabel: (state) => {
      return state.grades[state.person.grade]
    },
    gradeLabelByLocale: (state, getters, rootState, rootGetters) => (label: 'basic' | 'silver' | 'gold') => {
      const ENGLISH = 2
      return rootGetters['country/localeId'] !== ENGLISH
        ? vm.$i18n(`Benefit.label.Level.${toCapitalize(label)}`).value
        : ''
    },
    gradeForNext: (state, getters, rootState, rootGetters) => {
      const label = state.grades[state.person.grade + 1] || ''
      const isNextSilverGrade = state.person.accumulated_amount <
        vipAmountByCountry('gold', rootGetters['country/$currentSourceCountryCode'])
      const PERCENTAGE = 100
      const goldInProgress = state.person.accumulated_amount / vipAmountByCountry('gold', rootGetters['country/$currentSourceCountryCode']) * PERCENTAGE
      const goldAccumulatedAmount = goldInProgress > PERCENTAGE ? 100 : goldInProgress.toFixed(2)
      const achievementRate = isNextSilverGrade
        ? (state.person.accumulated_amount / vipAmountByCountry('silver', rootGetters['country/$currentSourceCountryCode']) * PERCENTAGE / 2).toFixed(2)
        : goldAccumulatedAmount
      return {
        label,
        amount: label
          ? vipAmountByCountry(label, rootGetters['country/$currentSourceCountryCode']) -
            state.person.accumulated_amount
          : 0,
        achievementRate
      }
    },
    isVerifyCompleteStatus: (state, getters, rootState, rootGetters) => {
      return getters.$getPid &&
       state.verifiedSourceCountries.includes(rootGetters['country/$currentSourceCountryObject'].id)
    }
  },
  mutations: {},
  actions: {
    async JWT_REFRESH ({ getters }) {
      try {
        const { data: { auth: jwt, refresh } } = await axios.post('/api/token/refresh', {
          pid: getters.$getPid,
          refresh: sessionStorage.refreshJwt
        })
        sessionStorage.jwt = jwt
        sessionStorage.refreshJwt = refresh
        sendToApp('jwtRefresh', { token: jwt, refreshToken: refresh })
      } catch (error) {
        throw error
      }
    }
  }
}

export default module
