
















import { defineComponent, reactive, toRefs, ref, watch } from '@vue/composition-api'
import { useOnOutsidePress } from 'vue-composable'
import { createDimEl } from '@/helpers'

interface State {
  [key: string]: any;
}

export default defineComponent({
  props: {
    height: {
      type: String,
      default: 'auto'
    },
    isOpened: {
      type: Boolean
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      close () {
        emit('close')
      }
    }) as State

    const toastEl = ref(null)

    useOnOutsidePress(toastEl, () => {
      props.isOpened && state.close()
    })

    watch(
      () => props.isOpened,
      () => {
        createDimEl(props.isOpened)
      }
    )

    return {
      ...toRefs(state),
      toastEl
    }
  }
})
