import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store/index'
import { remainingTimes } from '@/helpers'

const vm: any = new Vue()
interface Util {
  [key: string]: any;
}

const module: Module<Util, RootState> = {
  namespaced: true,
  state: {
    inputMessage: ''
  },
  getters: {
    title: () => (path = ''): string => {
      const map = {
        '/coupon-book': 'Benefit.button.ReceivingCoupons.None',
        '/help/faq': 'Help.title.faq.blank',
        '/my-coupon': 'Benefit.button.CouponBox.none',
        '/my-grade': 'Benefit.navigation.MyLevel.none',
        '/promotions/foryou': 'Global.label.event.blank'
      }
      return vm.$i18n(map[path as keyof typeof map]).value
    },
    toReadableTimes: () => (expirationDate: Date | string): string => {
      const inValidDate = new Date(expirationDate).getTime() <= 0
      const { days, hours, min, sec } = remainingTimes(expirationDate)
      if (inValidDate) return ''
      if (days) return vm.$i18n('Benefit.guide.CouponExpire.day', { day: days }).value
      if (hours) return vm.$i18n('Benefit.guide.CouponExpire.hour', { hour: hours, minute: min }).value
      if (min) return vm.$i18n('Benefit.guide.CouponExpire.minuite', { minute: min, second: sec }).value
      return vm.$i18n('Benefit.guide.CouponExpire.second', { second: sec }).value
    }
  },
  mutations: {},
  actions: {}
}

export default module
