import { computed, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import store from '@/store'
import { getDateTime, remainingTimes, toReadableNumber } from '@/helpers'
import { useI18n } from 'vue-composable'
import BigNumber from 'bignumber.js'
import {
  SettlementType
} from '@/types/enum'

import type { ICoupon, IMyCoupon } from '@/types'

interface State {
  [key: string]: any;
  getCard: ICoupon;
}
interface Func {
  [key: string]: any;
}

export default function (props: { card: ICoupon | IMyCoupon }): Func {
  const { $t } = useI18n()

  const {
    getters,
    state: {
      coupon
    }
  } = store

  const dateOfUse = () => {
    const isDateTime = new Date(state.getCard.usage_restrictions.effective_start_date).getTime() > 0 &&
      new Date(state.getCard.usage_restrictions.effective_end_date).getTime() > 0
    if (isDateTime) {
      return `${getDateTime(new Date(state.getCard.usage_restrictions.effective_start_date))} -
        ${getDateTime(new Date(state.getCard.usage_restrictions.effective_end_date))}`
    }
    if (state.getCard.usage_restrictions.effective_days) return $t('Benefit.guide.PeriodUse.Until', { days: state.getCard.usage_restrictions.effective_days }).value
    return $t('Benefit.guide.PeriodUse.Anytime').value
  }

  const labels = computed(() => {
    return {
      minRemittanceAmount: $t('Benefit.guide.Available.Morethan', { amount: `<b>${toReadableNumber(state.getCard.usage_restrictions.use_min_amount)}</b>`, currency: ` <b>${getters['country/toCurrencyIso'](+state.getCard.currency)}</b>` }).value,
      dateOfUse: dateOfUse()
    }
  })

  const state = reactive({
    currentTabIndex: {
      type: computed(() => coupon.currentTabIndex.type)
    },
    labels,
    getCard: computed(() => (props.card as IMyCoupon).coupon || props.card),
    remainingTimeLabel: '',
    remainingTimer: computed(() => state.remainingTimeLabel || getters['util/toReadableTimes'](state.getCard.visible_end)),
    remainingCoupons: computed(() => {
      const couponLength = state.getCard.issuance_restrictions.issue_count - state.getCard.issued_count
      return couponLength >= 0 ? couponLength : 0
    }),
    isMyCoupon: computed(() => (props.card as IMyCoupon).coupon),
    isRemainingTimerWithinDay: computed(() => {
      const { days } = remainingTimes(state.getCard.visible_end)
      return !days
    }),
    couponBenefitLabel: computed(() => {
      const {
        settlement_amount: {
          basic,
          silver,
          gold
        },
        settlement_type: settlementType
      } = state.getCard
      const settlementAmount = basic || silver || gold
      const RATE = 1
      const PERCENTAGE = 100
      const unit = settlementType === RATE ? '%' : getters['country/toCurrencyIso'](+state.getCard.currency)
      const brElement = settlementType === RATE ? '' : '<br>'
      const baseTapTypes = {
        [SettlementType.DISCOUNT_RATE]: `<span>${toReadableNumber(new BigNumber(settlementAmount).multipliedBy(PERCENTAGE))}${unit}</span> ${brElement} <span>${$t('Benefit.CouponTitle.FeeDiscount.Percent').value}</span>`,
        [SettlementType.DISCOUNT_FIXED]: `<span>${toReadableNumber(settlementAmount)}${unit}</span> ${brElement} <span>${$t('Benefit.CouponTitle.FeeDiscount.Percent').value}</span>`,
        [SettlementType.LUCKBOX_BLUE]: `<span>${$t('Benefit.button.Luckybox.none').value}</span> <span>${$t('Benefit.text.Numbers.none', { number: toReadableNumber(settlementAmount) }).value}</span>`,
        [SettlementType.LUCKBOX_GOLD]: `<span>${$t('Benefit.button.Luckybox.none').value}</span> <span>${$t('Benefit.text.Numbers.none', { number: toReadableNumber(settlementAmount) }).value}</span>`,
        [SettlementType.CASHBACK]: `<span>${toReadableNumber(settlementAmount)}${getters['country/toCurrencyIso'](+state.getCard.currency)}</span><br> <span>${$t('Benefit.CouponTitle.GetCash.none').value}</span>`,
        default: ''
      }
      return baseTapTypes[settlementType] || baseTapTypes.default
    }),
    couponType: computed(() => {
      const {
        settlement_type: settlementType
      } = state.getCard
      const type = {
        [SettlementType.DISCOUNT_RATE]: 'commission',
        [SettlementType.DISCOUNT_FIXED]: 'commission',
        [SettlementType.LUCKBOX_BLUE]: 'luckybox',
        [SettlementType.LUCKBOX_GOLD]: 'luckybox',
        [SettlementType.CASHBACK]: 'cash'
      }
      return type[settlementType]
    })
  }) as State

  let interval: any = null

  const runTimer = () => {
    interval = setInterval(() => {
      const { days, hours, min, sec } = remainingTimes(state.getCard.visible_end)
      const isTimedOutCoupon = [days, hours, min, sec].every(time => !time)
      state.remainingTimeLabel = getters['util/toReadableTimes'](state.getCard.visible_end)
      if (isTimedOutCoupon) {
        clearInterval(interval)
      }
    }, 1000)
  }

  state.isRemainingTimerWithinDay && runTimer()

  onUnmounted(() => {
    clearInterval(interval)
  })

  return {
    ...toRefs(state)
  }
}
