










































import { defineComponent } from '@vue/composition-api'
import store from '@/store'
import { sendToApp } from '@/helpers'
import { useI18n } from 'vue-composable'

export default defineComponent({
  setup (_, { emit }) {
    const { $t } = useI18n()

    const {
      state: {
        coupon
      },
      dispatch
    } = store

    const closeModal = () => {
      emit('close')
    }

    const useCoupon = async () => {
      try {
        const params = {
          card: coupon.recentlyReceivedCoupon,
          id: coupon.recentlyReceivedCoupon.coupon_box_id
        }
        await dispatch('coupon/USE_COUPON', params)
        closeModal()
        sendToApp('luckyBox', { baseUri: process.env.VUE_APP_SENTBE_DOMAIN, path: '/lucky_box', title: $t('Benefit.button.Luckybox.none').value })
      } catch {}
    }

    return {
      $t,
      closeModal,
      useCoupon
    }
  }
})
